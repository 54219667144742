import { CardAnchor, CardContainer, CardImage } from './styles'
import { AmplitudeEventTracking } from '@/helpers/amplitudeEventTracking'
import compressedImage from '@/helpers/compressedImage'
import { EventTracking } from '@/helpers/eventTracking'
import { useSession } from 'next-auth/react'
import Link from 'next/link'

// import { useSession } from 'next-auth/react'

const CardItem = ({
    image,
    imageMeta,
    title,
    value,

    entityType,
    entitySubType,
    badge,
}) => {
    const { data: session } = useSession()

    // const { data: session } = useSession()

    const OnBannerClick = () => {
        if (session?.token) {
            EventTracking(
                'banner_ad_clicked',
                {
                    bannerAdLink: image,
                    source: value,
                    bannerAdType: entitySubType,
                },
                session
            )

            AmplitudeEventTracking(
                'banner ad clicked',
                {
                    'banner ad link': image,
                    source: value,
                    'banner ad type': entitySubType,
                },
                session
            )
        }
    }

    const urlMap = {
        page: (value) =>
            value === 'livestream' ? '/livestream-list' : `/${value}`,
        themepage: (value) => `/${entitySubType}/${value}`,
        segment: (value) => `/${entitySubType}/${value}`,
        link: (value) => `${value}`,
    }
    const defaultUrl = ''

    const redirectUrl = urlMap[entitySubType]
        ? urlMap[entitySubType](value)
        : defaultUrl

    return (
        <Link href={redirectUrl} prefetch={false}>
            <CardAnchor onClick={() => OnBannerClick(entityType)}>
                <CardContainer>
                    <div
                        style={{
                            position: 'relative',
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <CardImage
                            loading="lazy"
                            src={compressedImage(image, imageMeta, true)}
                            alt={title}
                        />

                        {badge === 'vip' && (
                            <>
                                <img
                                    src="/img/vip.svg"
                                    alt="vip badge"
                                    style={{
                                        position: 'absolute',
                                        bottom: '8px',
                                        left: '8px',
                                        borderRadius: '0px',
                                        height: '16px',
                                        zIndex: '100',
                                    }}
                                />
                            </>
                        )}
                        {badge === 'earlyAccess' && (
                            <img
                                src="/img/early_access_new.svg"
                                alt="early access badge"
                                style={{
                                    position: 'absolute',
                                    bottom: '8px',
                                    left: '8px',
                                    borderRadius: '0px',
                                    height: '16px',
                                }}
                            />
                        )}
                        {badge === 'premium' && (
                            <img
                                src="/img/premium.svg"
                                alt="premium badge"
                                style={{
                                    position: 'absolute',
                                    bottom: '8px',
                                    left: '8px',
                                    borderRadius: '0px',
                                    height: '16px',
                                }}
                            />
                        )}
                    </div>
                </CardContainer>
            </CardAnchor>
        </Link>
    )
}

export default CardItem
